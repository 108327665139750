function ProjectTablePagination(props) {
  const { currentPage, totalRows, onPrevPage, onNextPage, rowsPerPage, rowsShown, loading } = props;

  const totalPages = totalRows > 0 ? Math.ceil(totalRows / rowsPerPage) : 0;
  const lastRow = Math.min(currentPage * rowsPerPage, totalRows);
  const firstRow = lastRow === 0 ? 0 : Math.max(lastRow - rowsPerPage + 1, 1);

  // Define a new variable to check if there are no rows
  const noRows = totalRows === 0;

  return (
    <div className='ProjectPagination'>
      <div className="pagination-container">
        <div className='pagination-main'>
          <div className='pagination-controls pagination-left-control'>
            {/* Disable the button if currentPage is 1 or there are no rows */}
            <button onClick={onPrevPage} disabled={loading || currentPage === 1 || noRows}>
              {'<'}
            </button>
          </div>
          <span>{`Page ${currentPage} of ${totalPages}`}</span>
          <div className='pagination-controls pagination-right-control'>
            {/* Disable the button if currentPage is the last page, or there are fewer rows shown than rows per page, or there are no rows */}
            <button onClick={onNextPage} disabled={loading || currentPage === totalPages || rowsShown < rowsPerPage || noRows}>
              {'>'}
            </button>
          </div>
        </div>

        <div className='pagination-info'>
          <span>{`Showing ${firstRow}-${lastRow} of ${totalRows} projects`}</span>
        </div>
      </div>
    </div>
  );
}

export default ProjectTablePagination;
