import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
import axios from 'axios';

// Redux

// ----------------------------------------------------------------------

let initialState = {
  searchValue: "",
  searchInputValue: "",
  serviceFilter: "All",
  serviceOptions: [],
  supplierListLoading: true,
  isserviceOptionsLoading: false,
  supplierList: { 'rows': [] },
  sortColumn: "",
  sortDirection: "asc",
  currentPage: 1,

  error: null,
};

export const suppliersSlice = createSlice({
  name: "suppliers",
  initialState,
  reducers: {

    // set filter values in redux
    setSearchValue(state, action) {
      state.searchValue = action.payload;
    },

    setServiceFilter(state, action) {
      state.serviceFilter = action.payload;
    },

    setSearchInputValue(state, action) {
      state.searchInputValue = action.payload;
    },

    setSupplierList(state, action) {
      state.supplierList = action.payload;
      state.supplierListLoading = false;
    },

    setServiceOptions(state, action) {
      state.serviceOptions = action.payload;
      state.isserviceOptionsLoading = false;
    },

    setSortDirection(state, action) {
      state.sortDirection = action.payload;
    },

    setSortColumn(state, action) {
      state.sortColumn = action.payload;
    },

    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },

    setSuppliersListLoading(state, action) {
      state.supplierListLoading = action.payload;
    },

    setServiceOptionsLoading(state, action) {
      state.isserviceOptionsLoading = action.payload;
    },



    // HAS ERROR
    hasError(state, action) {
      state.isinvSummaryTableLoading = false;
      state.error = action.payload;
    },


  },

});

export const {
  setSearchValue,
  setServiceFilter,
  setSearchInputValue,
  setSortColumn,
  setSortDirection,
  setCurrentPage
} = suppliersSlice.actions;

// Reducer
export function getSuppliersList(serviceFilter, searchValue, sortColumn, sortDirection, rowsPerPage, page) {
  const API_URL = process.env.REACT_APP_API_URL;
  return async () => {
    dispatch(suppliersSlice.actions.setSuppliersListLoading(true));
    try {
      // Construct the query string
      const queryParams = new URLSearchParams({
        serviceFilter,
        page,
        rowsPerPage,
        searchValue,
        sortColumn,
        sortDirection
      }).toString();

      const response = await axios.get(`${API_URL}/api/suppliers/pageData?${queryParams}`);
      dispatch(suppliersSlice.actions.setSupplierList(response.data));
    } catch (error) {
      console.error(error);
      dispatch(suppliersSlice.actions.hasError(error));
    }
  };
}

export function fetchServiceOptions() {
  const API_URL = process.env.REACT_APP_API_URL;
  return async (dispatch) => {
    dispatch(suppliersSlice.actions.setServiceOptionsLoading(true));
    try {
      const result = await axios.get(`${API_URL}/api/suppliers/serviceOptions`);
      const transformedData = result.data.map(service => service);
      transformedData.unshift('All'); // Adding 'All' to the beginning
      dispatch(suppliersSlice.actions.setServiceOptions(transformedData));
    } catch (error) {
      console.error('Error fetching service options:', error);
      dispatch(suppliersSlice.actions.hasError(error));
    } finally {
      dispatch(suppliersSlice.actions.setServiceOptionsLoading(false));
    }
  };
}

export default suppliersSlice.reducer;