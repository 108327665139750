import React from 'react';
import '../../App.css';
import Dashboard from '../../assets/Dashboard.svg';
import Opportunities from '../../assets/Opportunities.svg';
import Projects from '../../assets/Projects.svg';
import TimeSheets from '../../assets/Time Sheets.svg';
import Clients from '../../assets/Clients.svg';
import Invoicing from '../../assets/Invoicing.svg';
import SharePoint from '../../assets/Sharepoint.svg';
import Suppliers from '../../assets/Suppliers.svg';
import Planner from '../../assets/Resourcing.svg';
import Profile from '../../assets/Profile.svg';
import Approvals from '../../assets/Approvals.svg';
import styles from './Sidebarlist.module.css';


export const SidebarData = [
  {
    title: 'Dashboard',
    icon: <img className={styles.SidebarIcons} src={Dashboard} alt='Dashboard' />,
    link: '/dashboard',
    disabled: false,
  },
  {
    title: 'Opportunities',
    icon: <img className={styles.SidebarIcons} src={Opportunities} alt='Projects' />,
    link: '/Opportunities',
    disabled: false,
  },
  {
    title: 'Projects',
    icon: <img className={styles.SidebarIcons} src={Projects} alt='Projects' />,
    link: '/projects',
    disabled: false,
  },
  {
    title: 'Time Sheets',
    icon: <img className={styles.SidebarIcons} src={TimeSheets} alt='TimeSheets' />,
    link: '/timesheets',
    disabled: false,
  },
  {
    title: 'Clients',
    icon: <img className={styles.SidebarIcons} src={Clients} alt='Clients' />,
    link: '/clients',
    disabled: false,
  },
  {
    title: 'Invoicing',
    icon: <img className={styles.SidebarIcons} src={Invoicing} alt='Invoicing' />,
    link: '/invoicing',
    disabled: false,
  },
  {
    title: 'Suppliers',
    icon: <img className={styles.SidebarIcons} src={Suppliers} alt='Suppliers' />,
    link: '/suppliers',
    disabled: false,
  },
  {
    title: 'Planner',
    icon: <img className={styles.SidebarIcons} src={Planner} alt='Planner' />,
    link: 'https://planner.cloud.microsoft/blueengineering.co.uk/en-GB/Home/Planner/#/plantaskboard?groupId=aaea5cdf-81b5-4d4d-a94e-1dbc650f248f&planId=ZDAKVApkl02-qVxRHWmDlpcAAuYb',
    disabled: false,
  },
  {
    title: 'SharePoint',
    icon: <img className={styles.SidebarIcons} src={SharePoint} alt='SharePoint' />,
    link: 'https://blueengineering.sharepoint.com',
    disabled: false,
  },
  {
    title: 'Profile',
    icon: <img className={styles.SidebarIcons} src={Profile} alt='Profile' />,
    link: '/Profile',
    disabled: false,
  },
  {
    title: 'Admin',
    icon: <img className={styles.SidebarIcons} src={Approvals} alt='Admin' />,
    link: '/Admin',
    disabled: false,
  },
];