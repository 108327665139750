import React, { useState, useEffect, useContext } from 'react';
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from '../../../redux/store';
import AdminContext from '../../../Context/adminContext';
import SearchBar from '../../../Components/SearchBar/SearchBar';
import styles from './SuppliersList.module.css';
import {
  getSuppliersList,
  fetchServiceOptions,
  setServiceFilter,
  setSearchValue,
  setSearchInputValue,
  setCurrentPage,
  setSortColumn,
  setSortDirection,
} from "../../../redux/slices/suppliers";

import ClientForm from '../../../Components/ClientForm/ClientForm';
import Dropdown from "../../../Components/DropDownBox/Dropdown";
import ApiResponseDisplay from '../../../Components/ApiResponseDisplay/ApiResponseDisplay';
import ProjectTablePagination from '../../Projects/ProjectList/ProjectTablePagination';
import SupplierListTable from '../../../Sections/supplier/SupplierListTable';

function SupplierList() {
  const { accounts } = useMsal();
  const dispatch = useDispatch();
  const account = accounts[0];
  const admin = useContext(AdminContext);
  const [addNewPopUp, setAddNewPopUp] = useState(false);
  const [isResponseShown, setIsResponseShown] = useState(false);
  const [paginationLoading, setPaginationLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const {
    currentPage,
    supplierList,
    serviceFilter,
    serviceOptions,
    searchValue,
    searchInputValue,
    supplierListLoading,
    isserviceOptionsLoading,
    sortDirection,
    sortColumn,
    error,
  } = useSelector((state) => state.suppliers);


  const totalPages = supplierList.totalRows > 0 ? Math.ceil(supplierList.totalRows / rowsPerPage) : 0;
  const currentPageValue = supplierList.totalRows > 0 ? currentPage : 0;

  useEffect(() => {
    dispatch(getSuppliersList(serviceFilter, searchValue, sortColumn, sortDirection, rowsPerPage, currentPage));
    setPaginationLoading(false);
    dispatch(fetchServiceOptions());
  }, [serviceFilter, sortColumn, sortDirection, currentPage, searchValue]);

  const handleSort = (column) => {
    if (column === sortColumn) {
      dispatch(setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc'));
    } else {
      dispatch(setSortColumn(column));
      dispatch(setSortDirection('asc'));
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setPaginationLoading(true);
      dispatch(setCurrentPage(currentPage - 1));
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setPaginationLoading(true);
      dispatch(setCurrentPage(currentPage + 1));
    }
  };

  const handleSearch = (event) => {
    dispatch(setSearchInputValue(event.target.value));
  };

  const handleSearchSubmit = () => {
    dispatch(setSearchValue(searchInputValue));
    dispatch(setCurrentPage(1));
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearchSubmit();
    }
  };

  // WIP
  const handleAddNewClientSubmit = (formData) => {
    // dispatch(createClient(formData));
    setAddNewPopUp(false);
    dispatch(setSearchInputValue(''));
    dispatch(setSearchValue(''));
  }

  const handleServiceFilter = (type) => {
    dispatch(setServiceFilter(type));
    dispatch(setCurrentPage(1));
  };

  return (
    <div className={styles.SuppliersMainContent}>
      <div className={styles.SuppliersHeader}>
        <div className={styles.SuppliersTitle}>
          Suppliers List
        </div>
      </div>

      <div className={styles.SuppliersTableFilters}>
        <div className={styles.Filters}>
          <div className={styles.FilterContainer}>
            {/* <label className={styles.FilterLabel} htmlFor='StatusFilter' style={{ fontSize: '12px' }}>Status:</label> */}
            {/* <div className={styles.dropdownContainer}>
              <Dropdown options={statusOptions} initialSelectedValue={statusFilter} onValueChange={handleStatusFilter} disabled={supplierListLoading} />
            </div> */}
            
            <label className={styles.FilterLabel} htmlFor='ServiceFilter' style={{ fontSize: '12px' }}>Service:</label>
            <div className={styles.dropdownContainer}>
              <Dropdown options={serviceOptions} initialSelectedValue={serviceFilter} onValueChange={handleServiceFilter} options_width='190%' disabled={supplierListLoading} />
            </div>
          </div>
        </div>

        <div className={styles.SearchBar}>
          {admin && (
            <div className={`${styles.AddBtn} ${supplierListLoading ? styles.AddDisabled : ''}`} onClick={!supplierListLoading ? () => setAddNewPopUp(true) : null}>
              ADD
            </div>
          )}
          <SearchBar value={searchInputValue} onChange={handleSearch} onKeyDown={handleKeyDown} disabled={supplierListLoading} onClick={handleSearchSubmit} />

        </div>
      </div>

      {supplierListLoading ? (
        <React.Fragment>
          <SupplierListTable
            rows={[]}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            handleSort={handleSort}
            loading={supplierListLoading}
          />

          <ProjectTablePagination
            currentPage={currentPageValue}
            totalPages={totalPages}
            onPrevPage={paginationLoading ? null : handlePrevPage} // disable if loading
            onNextPage={paginationLoading ? null : handleNextPage} // disable if loading
            rowsShown={supplierList.rows.length}
            totalRows={supplierList.totalRows}
            rowsPerPage={rowsPerPage}
            loading={supplierListLoading}
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <SupplierListTable
            rows={supplierList.rows}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            handleSort={handleSort}
            loading={supplierListLoading}
          />

          <ProjectTablePagination
            currentPage={currentPageValue}
            totalPages={totalPages}
            onPrevPage={paginationLoading ? null : handlePrevPage} // disable if loading
            onNextPage={paginationLoading ? null : handleNextPage} // disable if loading
            rowsShown={supplierList.rows.length}
            totalRows={supplierList.totalRows}
            rowsPerPage={rowsPerPage}
            loading={supplierListLoading}
          />

          {error && error !== null && (
            <ApiResponseDisplay apiResponse={error} setIsResponseShown={setIsResponseShown} />
          )}

          {addNewPopUp && (
            <ClientForm
              Name={''}
              onSubmit={handleAddNewClientSubmit}
              onClose={() => setAddNewPopUp(false)}
            />
          )}
        </React.Fragment>
      )}
    </div>
  )
}

export default SupplierList;