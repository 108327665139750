import React from 'react';
import { Button } from '@mui/material';

import styles from './ApiResponseDisplay.module.css';
import buttonStyle from '../../GlobalStyles/button.module.css';


import { useSelector, useDispatch } from '../../redux/store';
import {
    hasError,
} from "../../redux/slices/opportunitiesdata";

const ApiResponseDisplay = ({ apiResponse, setIsResponseShown }) => {
    const dispatch = useDispatch();

    return (
        <div className={styles.Overlay}>
            <div className={styles.ResponseBox}>
                <div
                    style={{
                        fontSize: 16,
                        fontFamily: 'PT Sans',
                        textAlign: 'center',
                        margin: '20px',
                        marginLeft: '25px',
                        marginRight: '25px'
                    }}
                    dangerouslySetInnerHTML={{ __html: apiResponse }}
                >
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button className={buttonStyle.Button} onClick={() => {
                        setIsResponseShown(false);
                        dispatch(hasError(null));
                    }}>
                        OK
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ApiResponseDisplay;
