import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from '../../../redux/store';
import { setteamFilter, setBidSwitch, setstatusFilter, setrunnerFilter, setsearchTerm, setinputValue, setSortDirection, setSortColumn, setCurrentPage, setScrollPos, setLastStatus } from '../../../redux/slices/filters';
import axios from 'axios';
import ProjectListTable from './ProjectListTable';
import SearchBar from '../../../Components/SearchBar/SearchBar';
import SliderSwitch from '../../../Components/SliderSwitch/SliderSwitch';
import ProjectTablePagination from './ProjectTablePagination';
import AdminContext from '../../../Context/adminContext';
import { useMsal } from "@azure/msal-react";
import './ProjectList.css';
import Filter from '../../../Components/Filter/Filter';

import {
  getProjectList,
  setProjectListData,
  setProjectListRowCount,
  setProjectListLoading,
  fetchFullProjectExport,
  fetchPhasesExport,
} from "../../../redux/slices/project";

function Projects() {
  const API_URL = process.env.REACT_APP_API_URL;
  const { accounts } = useMsal();
  const dispatch = useDispatch();
  const account = accounts[0];

  const admin = useContext(AdminContext);

  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [teamOptions, setTeamOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [runnerOptions, setRunnerOptions] = useState([]);

  const [paginationLoading, setPaginationLoading] = useState(false);

  const [fetchedTeam, setFetchedTeam] = useState('');
  const listBoxRef = React.useRef();

  let allRows = useSelector((state) => state.project.rows);
  let totalRows = useSelector((state) => state.project.totalRows);

  let loading = useSelector((state) => state.project.loading);

  let scrollPos = useSelector((state) => state.filters.scrollPos);

  const BidSwitch = useSelector((state) => state.filters.BidSwitch);
  const searchTerm = useSelector((state) => state.filters.searchTerm);
  const teamFilter = useSelector((state) => state.filters.teamFilter);
  const statusFilter = useSelector((state) => state.filters.statusFilter);
  const runnerFilter = useSelector((state) => state.filters.runnerFilter);
  const inputValue = useSelector((state) => state.filters.inputValue);
  const sortDirection = useSelector((state) => state.filters.sortDirection);
  const sortColumn = useSelector((state) => state.filters.sortColumn);
  const currentPage = useSelector((state) => state.filters.currentPage);

  const LastStatus = useSelector((state) => state.filters.LastStatus);

  const fetchTeamOptions = async () => {
    try {
      const result = await axios.get(`${API_URL}/api/teamOptions`);
      const transformedData = result.data.map(team => ({ name: team, value: team }));
      transformedData.unshift({ name: 'All', value: 'All' });
      setTeamOptions(transformedData);
      console.log("team", transformedData)
    } catch (error) {
      console.error('Error fetching teams:', error);
    }
  };

  const fetchRunnerOptions = async () => {
    try {
      const result = await axios.get(`${API_URL}/api/projects/runnerOptions?mode=Initials`);
      const transformedData = result.data.map(runner => ({ name: runner, value: runner, team: runner }));
      transformedData.unshift({ name: 'All', value: 'All', team: 'None' });
      setRunnerOptions(transformedData);
      console.log(transformedData)
    } catch (error) {
      console.error('Error fetching runners:', error);
    }
  };

  const fetchStatuses = async () => {
    try {
      const result = await axios.get(`${API_URL}/api/projects/statusOptions`);
      const transformedData = result.data.map(status => ({
        name: status,
        value: status.replace(/-/g, " ")
      }));
      transformedData.unshift({ name: 'All - Open', value: '' });
      transformedData.unshift({ name: 'All - Projects', value: 'All - Projects' });
      setStatusOptions(transformedData);
    } catch (error) {
      console.error('Error fetching statuses:', error);
    }
  };

  const fetchTeam = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/user/team?UID=${account.username}`, {
        headers: {
          'Content-Type': 'application/json',
        }
      });

      const data = response.data;
      return data.team || ''; // Ensure that a string is returned

    } catch (error) {
      console.error(error);
      throw new Error(`HTTP error! status: ${error.response.status}`);
    }
  };

  const loadData = async () => {
    try {

      // Uncomment when multiple teams exist again
      // const team = teamFilter !== '' ? teamFilter : await fetchTeam();

      // if (team === "Devs" || team === "Fields") {
      //   setFetchedTeam("All");
      // } else {
      //   setFetchedTeam(team);
      // }


      // Remove the below line when multiple teams exist again
      setFetchedTeam("All");
      const team = "Devs"

      dispatch(getProjectList(currentPage === 1, rowsPerPage, searchTerm, team, statusFilter, sortColumn, sortDirection, runnerFilter));

      setPaginationLoading(false);
    } catch (error) {
      console.error(error);
      dispatch(setProjectListLoading(false));
      setPaginationLoading(false);
    }
  };

  useEffect(() => {
    fetchTeamOptions();
    fetchStatuses();
    fetchRunnerOptions();
  }, []);

  useEffect(() => {
    loadData();
  }, [searchTerm, sortColumn, sortDirection, statusFilter, runnerFilter, currentPage, rowsPerPage, teamFilter]);

  useEffect(() => {
    if (!BidSwitch) dispatch(setLastStatus(statusFilter));
  }, [statusFilter])

  const handleSearch = (event) => {
    dispatch(setinputValue(event.target.value));
  };

  const handleSearchSubmit = () => {
    dispatch(setsearchTerm(inputValue));
    dispatch(setCurrentPage(1));
  };

  const handleTeamFilter = (team) => {
    console.log(`handleTeamFilter called with team: ${team}`);
    if (team === "Devs" || team === "Fields") {
      team = ""
      dispatch(setteamFilter(team));
    } else {
      dispatch(setteamFilter(team));
    }
    dispatch(setCurrentPage(1));

    // Save scroll position
    if (listBoxRef.current) {
      dispatch(setScrollPos(listBoxRef.current.scrollTop));
    }

    if (team.toLowerCase() !== "all" && team.toLowerCase() !== "") {
      dispatch(setSortColumn('nextdeadline'));
      setSortDirection('asc');
    } else if ((team.toLowerCase() == "all" || team.toLowerCase() == "") && statusFilter.toLowerCase() == "live") {
      dispatch(setSortColumn('promoted'));
      dispatch(setSortDirection('asc'));
    }
  };

  const handleStatusFilter = (status) => {
    dispatch(setstatusFilter(status));
    dispatch(setCurrentPage(1));
  };

  const handleRunnerFilter = (runner) => {
    dispatch(setrunnerFilter(runner));
    dispatch(setCurrentPage(1));
  };

  const handleBIDSwitch = (state) => {
    dispatch(setBidSwitch(state));
    dispatch(setCurrentPage(1));
    if (state) {
      dispatch(setstatusFilter('On hold'));
      dispatch(setSortColumn('followup'));
      dispatch(setSortDirection('asc'));
    }
    else dispatch(setstatusFilter(LastStatus));
    // console.log("Current switch state: ", state);
  }

  const handleSort = (column) => {
    if (column === sortColumn) {
      dispatch(setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc'));
    } else {
      dispatch(setSortColumn(column));
      dispatch(setSortDirection('asc'));
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setPaginationLoading(true);
      dispatch(setCurrentPage(currentPage - 1));
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setPaginationLoading(true);
      dispatch(setCurrentPage(currentPage + 1));
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearchSubmit();
    }
  };

  React.useEffect(() => {
    // Restore scroll position
    if (scrollPos && listBoxRef.current) {
      listBoxRef.current.scrollTop = parseFloat(scrollPos);
    }
  }, [scrollPos]);


  const totalPages = totalRows > 0 ? Math.ceil(totalRows / rowsPerPage) : 0;
  const currentPageValue = totalRows > 0 ? currentPage : 0;

  return (
    <div className='projectContent'>
      <div className='ProjectHeader'>
        <div className='Projecttitle'>Project List</div>
        <div className='ButtonGroup'>
          {admin &&
            <React.Fragment>
              <div className={`Btn ${loading ? 'AddDisabled' : ''}`} style={{ width: 'auto', paddingLeft: '10px', paddingRight: '10px' }} onClick={!loading ? () => dispatch(fetchFullProjectExport()) : null}>
                PROJECT EXPORT
              </div>

              <div className={`Btn ${loading ? 'AddDisabled' : ''}`} style={{ width: 'auto', paddingLeft: '10px', paddingRight: '10px' }} onClick={!loading ? () => dispatch(fetchPhasesExport()) : null}>
                PHASE EXPORT
              </div>
            </React.Fragment>
          }
        </div>
      </div>
      <div className='ProjectTableFilters'>
        <div className='StatusNdTeam'>
          <div className='ProjectTeamFilter'>
            <label className='FilterLabel' htmlFor='teamFilter' style={{ fontSize: '12px' }}>Team:</label>
            <Filter id='teamFilter' Options={teamOptions} selectedOption={fetchedTeam} onSelect={handleTeamFilter} disabled={loading} />
          </div>

          {!BidSwitch && (
            <React.Fragment>
              <div className='ProjectStatusFilter'>
                <label className='FilterLabel' htmlFor='statusFilter' style={{ fontSize: '12px' }}>Status:</label>
                <Filter id='statusFilter' Options={statusOptions} selectedOption={statusFilter === "" ? "All - Open" : statusFilter} onSelect={handleStatusFilter} disabled={loading} />
              </div>

              <div className='ProjectStatusFilter'>
                <label className='FilterLabel' htmlFor='RunnerLabel' style={{ fontSize: '12px' }}>Runner:</label>
                <Filter id='RunnerLabel' Options={runnerOptions} selectedOption={runnerFilter} onSelect={handleRunnerFilter} disabled={loading} />
              </div>
            </React.Fragment>
          )}

          {admin && (
            <div className='ProjectBIDSwitch'>
              <label className='FilterLabel' htmlFor='BidSwitch' style={{ fontSize: '12px' }}>BID:</label>
              <SliderSwitch id='BidSwitch' checked={BidSwitch} onToggle={handleBIDSwitch} disabled={loading} />
            </div>
          )}
        </div>

        <div className='ProjectListSearch'>
          <SearchBar value={inputValue} onChange={handleSearch} onKeyDown={handleKeyDown} disabled={loading} onClick={handleSearchSubmit} />
        </div>
      </div>

      {loading ? (
        <>
          <div className='ProjectListBox'>
            <ProjectListTable
              rows={[]} // Send empty rows while loading
              bid={BidSwitch}
              sortColumn={sortColumn}
              sortDirection={sortDirection}
              handleSort={handleSort}
              loading={loading} // Send loading status to ProjectListTable
            />
          </div>

          <ProjectTablePagination
            currentPage={currentPageValue}
            totalPages={totalPages}
            onPrevPage={paginationLoading ? null : handlePrevPage} // disable if loading
            onNextPage={paginationLoading ? null : handleNextPage} // disable if loading
            rowsShown={allRows.length}
            totalRows={totalRows}
            rowsPerPage={rowsPerPage}
            loading={loading}
          />
        </>
      ) : (
        <>
          <div className='ProjectListBox' ref={listBoxRef}>
            <ProjectListTable
              rows={allRows}
              bid={BidSwitch}
              loading={loading}
              handleSort={handleSort}
              sortDirection={sortDirection}
              sortColumn={sortColumn}
            />

          </div>

          {allRows.length === 0 && <div className='NoProjects'>THE RECORD YOU HAVE SEARCHED CANNOT BE FOUND</div>}

          <ProjectTablePagination
            currentPage={currentPageValue}
            totalPages={totalPages}
            onPrevPage={paginationLoading ? null : handlePrevPage} // disable if loading
            onNextPage={paginationLoading ? null : handleNextPage} // disable if loading
            rowsShown={allRows.length}
            totalRows={totalRows}
            rowsPerPage={rowsPerPage}
          />

        </>
      )}
    </div>
  );

}

export default Projects;